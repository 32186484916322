import request from '../utils/request';

/**
 * 查询零元卡申请列表
 * @param param
 * @returns {*}
 */
export const zeroOrderApplyPageList = (param) => {
    return request({
        url: '/sys/zeroOrderApply/zeroOrderApplyPageList',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(param),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};

/**
 * 查询零元卡申请列表
 * @param param
 * @returns {*}
 */
export const exportZeroOrderApplyList = (param) => {
    return request({
        url: '/sys/zeroOrderApply/exportZeroOrderApplyList',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(param),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};