<template>
  <div style="padding: 20px">
    <el-cascader
      placeholder="请选择区域"
      :options="treeList"
      collapse-tags
      :collapse-tags-tooltip="true"
      clearable
      @change="changeArea"
      :props="{
          multiple: true,
          value: 'id',
          label: 'name',
          children: 'children',
        }"
    />
      <el-select placeholder="城市类型" style="width: 120px;margin:0 10px" v-model="query.flag">
        <div>
          <el-option label="全部" value="0"></el-option>
          <el-option label="中招体育" value="1"></el-option>
          <el-option label="少年" value="2"></el-option>
          <el-option label="高考体育" value="4"></el-option>
        </div>
    </el-select>
    <el-input placeholder="学生姓名、新、旧订单id" v-model="query.keyWord" style="width: 250px;margin-right: 10px"></el-input>
    <el-select placeholder="状态" style="width: 120px;margin-right: 10px" v-model="query.status">
      <div>
        <el-option label="待审核" value="1"></el-option>
        <el-option label="已通过" value="2"></el-option>
        <el-option label="已拒绝" value="3"></el-option>
      </div>
    </el-select>
    <el-date-picker
        style="width: 260px;margin-right: 10px"
        v-model="query.dateList"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
    >
    </el-date-picker>
    <el-button
        type="primary"
        icon="Search"
        style="margin-left: 10px"
        @click="getList">搜索
    </el-button>
    <el-button
        type="primary"
        style="margin-left: 10px"
        @click="exportFile">下载
    </el-button>
    <el-table
        :data="tableDataList"
        border
        v-loading="tableLoading"
        :row-style="{height:10+'px'}"
        :cell-style="{padding:14+'px'}"
        class="table"
        style="margin-top: 40px"
        ref="multipleTable"
        height="606px"
        header-cell-class-name="table-header"
    >
      <el-table-column align="center" width="90" show-overflow-tooltip="true" prop="id" label="ID"/>
      <el-table-column align="center" width="120" show-overflow-tooltip="true" prop="applyName" label="申请老师"/>
      <el-table-column align="center" width="140" show-overflow-tooltip="true" prop="areaName" label="校区名称"/>
      <el-table-column align="center" width="140" show-overflow-tooltip="true" prop="areaCity" label="城市"/>
      <el-table-column align="center" width="140" show-overflow-tooltip="true" prop="flagName" label="校区类型"/>
      <el-table-column align="center" width="120" show-overflow-tooltip="true" prop="studentName" label="学员姓名"/>
      <el-table-column align="center" width="140" show-overflow-tooltip="true" prop="cardName" label="卡片名称"/>
      <el-table-column align="center" width="180" show-overflow-tooltip="true" prop="startTime" label="开始时间"/>
      <el-table-column align="center" width="180" show-overflow-tooltip="true" prop="endTime" label="结束时间"/>
      <el-table-column align="center" width="90" show-overflow-tooltip="true" prop="newOrderId" label="新订单ID"/>
      <el-table-column align="center" width="90" show-overflow-tooltip="true" prop="oldOrderId" label="旧订单ID"/>
      <el-table-column align="center" width="160" show-overflow-tooltip="true" prop="remark" label="备注"/>
      <el-table-column align="center" width="90" show-overflow-tooltip="true" prop="statusName" label="状态"/>
      <el-table-column align="center" width="180" show-overflow-tooltip="true" prop="createTime" label="创建时间"/>
    </el-table>
    <div class="pagination">
      <el-pagination
          background
          layout="total, prev, pager, next"
          :current-page="query.pageIndex"
          :page-size="query.pageSize"
          :total="pageTotal"
          @current-change="handlePageChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import {zeroOrderApplyPageList,exportZeroOrderApplyList} from "@/api/free.js"
import {listAreaTreeAddCoach} from "@/api/area.js";
export default {
  name: "Free",
  data() {
    return {
      treeList:[],
      pageTotal:0,
      tableDataList:[],
      tableLoading:false,
      query:{
        pageIndex: 1,
        pageSize: 10,
        dateList:[],
        areaList:[],
        flag:null,
      }
    }
  },
  methods: {
    //请选择区域
    changeArea(val){
      this.query.areaList  = []
      let  areaList = this.query.areaList 
      // console.log(val[val.length-1]);
      // this.query.areaList = val== null ? null : val[val.length-1]
      val.forEach(function(item){
        areaList.push(...item)
      })
      
      // this.query.areaList = val;
    },
    getTypeList(commodityType) {
      if (commodityType!=null){
        commodityType.forEach(items => {
          if (items!=null){
            if (items.children!=null && items.children.length > 0) {
              this.getTypeList(items.children);
            } else {
              items.children = undefined;
            }
          }
        });
        return commodityType;
      }
    },
    getAreaList(typeId){
      listAreaTreeAddCoach(typeId).then(val => {
        if (val.code==200){
          this.getTypeList(val.data);
          this.treeList = val.data;
        }
      })
    },
    handlePageChange(pageIndex){
      this.query.pageIndex = pageIndex;
      this.getList();
    },
    dateFormat(val) {
      var date = new Date(val);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      const time = y + "-" + m + "-" + d;
      return time;
    },
    getList(){
      if (this.query.dateList.length >= 2) {
        this.query.startDate = this.dateFormat(this.query.dateList[0]);
        this.query.endDate = this.dateFormat(this.query.dateList[1]);
      }else {
        this.query.startDate = null;
        this.query.endDate = null;
      }
      this.tableLoading = true;
      zeroOrderApplyPageList(this.query).then(val=>{
        if (val.code == 200) {
          this.tableLoading = false;
          this.tableDataList = val.data.records;
          this.pageTotal = val.data.total;
        } else {
          this.$message.error("获取列表失败！");
        }
      })
    },
    exportFile(){
      exportZeroOrderApplyList(this.query)
    }
  },
  created() {
    this.getList();
    this.getAreaList();
  }
}
</script>

<style scoped>

</style>